import React from "reactn"
import { graphql } from "gatsby"
import { Layout } from "../../components/layoutRoot"
import { SectionListing } from "../../components/section-listing"
import { Box, Center } from "@chakra-ui/react"
import { Seo } from "../../components/seo"
import DataLayer from "../../components/dataLayer"

export default function Story({ location, data: { story } }) {
  const {
    seoMetaTags,
    sections
  } = story
  return (
    <>
      <DataLayer location={location} />
      <Seo seoMetaTags={seoMetaTags} og_type={'article'} location={location} />
      <Center>
      <Box maxWidth={'80%'}>
          <SectionListing sections={sections} isStory={true}/>            
      </Box>
      </Center>        
    </>
  )
}
Story.Layout = Layout

export const query = graphql`
  query($id: String!) {
    story: datoCmsStory(id: { eq: $id }) {
      internal{
        type
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      sections {
        ... on DatoCmsSection {
          ...StorySectionCard
        }
      }
    }    
  }
`
